<route>
{
	"name": "login",
  	"meta": {
    	"guestAccess": true
  }
}
</route>
<template>
	<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-sc-login">
		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10" :class="{ 'pointer-events-none': loading }">
				<div>
					<img src="/assets/images/login-page-logo.png" class="logo" />
				</div>
				<h2 class="my-6 text-center text-2xl font-extrabold text-gray-600">
					Sign in to your account
				</h2>
				<form class="space-y-6" action="#" method="POST" @submit.prevent="handleLogin">
					<div>
						<label for="username" class="block text-sm font-medium text-gray-700">
							Username
						</label>
						<div class="mt-1">
							<input
								id="username"
								v-model="username"
								name="username"
								type="text"
								autocomplete="username"
								required=""
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
					</div>

					<div>
						<label for="password" class="block text-sm font-medium text-gray-700">
							Password
						</label>
						<div class="mt-1">
							<input
								id="password"
								v-model="password"
								name="password"
								type="password"
								autocomplete="current-password"
								required=""
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							/>
						</div>
					</div>

					<div class="flex items-center justify-between">
						<div class="flex items-center">
							<!-- <input
								id="remember_me"
								name="remember_me"
								type="checkbox"
								class="h-4 w-4 text-sc-blue focus:ring-blue-500 border-gray-300 rounded"
							/>
							<label for="remember_me" class="ml-2 block text-sm text-gray-900">
								Remember me
							</label> -->
						</div>

						<div class="text-sm">
							<a href="/forgot-password" class="font-medium text-sc-blue hover:text-sc-blue-hover">
								Forgot your password?
							</a>
						</div>
					</div>

					<div>
						<button
							type="submit"
							class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-sc-blue hover:bg-sc-blue-hover"
							:disabled="loading"
						>
							<IconButtonLoading :loading="loading" />
							Sign in
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
	name: 'Login',
	layout: 'full',
	setup() {
		const username = ref('')
		const password = ref('')

		const toast = useToast()

		const router = useRouter()
		const store = useStore()
		//const loggedIn = computed(() => store.getters.user.isLoggedIn)
		//console.log('isLoggedIn: ', loggedIn)
		//console.log('lstore: ', localStorage.getItem('loggedIn'))
		const loading = ref(false)

		const handleLogin = () => {
			// handle user login
			//console.log('sending login!')
			loading.value = true

			store
				.dispatch('user/login', {
					username: username.value,
					password: password.value,
				})
				.then(response => {
					//console.log('Login Success! Response: ', response)
					toast.clear()
					toast.success('Successfully logged in!')
					router.replace(router?.currentRoute?.value?.query?.redirect ?? '/')
				})
				.catch(error => {
					//console.log('Failed to login: ', error.message)
					toast.error(error.message)
				})
				.finally(response => {
					loading.value = false
				})
		}
		return {
			username,
			password,
			handleLogin,
			store,
			toast,
			loading,
		}
	},
}
</script>

<style scoped lang="scss">
.bg-sc-login {
	background-image: url('/assets/images/rsz_login-page-bg.jpg');
	background-size: cover;
}
.logo {
	margin: 0 auto;
}
</style>
